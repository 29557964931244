import { FC } from "react";
import { GetStaticProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from "next-i18next";

import { getStaticData } from "@lib/prisma";
import { revalidate } from "@constants/revalidate";
import ErrorPage from "@components/error/error-page";
import { StaticProps } from "@interfaces/page";

const NotFoundPage: FC = () => {
  const { t } = useTranslation("error");

  return (
    <ErrorPage
      title={t("404.title")}
      statusCode={404}
      message={t("404.message")}
    />
  );
};

export default NotFoundPage;

export const getStaticProps: GetStaticProps<StaticProps> = async ({
  locale,
}) => {
  const [staticData] = await getStaticData([]);

  const translations = await serverSideTranslations(locale as string, [
    "common",
    "error",
  ]);

  return {
    props: { staticData, ...translations },
    revalidate,
  };
};
