import { FC } from "react";

import Layout from "@components/common/layout";

type Props = {
  statusCode: number;
  message: string;
  title: string;
};

const ErrorPage: FC<Props> = ({ statusCode, message, title }) => {
  return (
    <Layout
      className="min-h-screen flex flex-col justify-between bg-white"
      title={title}
      description=""
    >
      <div className="bg-white flex justify-center items-center min-h-2/3-screen">
        <h1 className="pr-4 py-2 border-r border-black font-medium">
          {statusCode}
        </h1>
        <p className="pl-4">{message}</p>
      </div>
    </Layout>
  );
};

export default ErrorPage;
